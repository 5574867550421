import {
  useQuery,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { useApi } from '../../state/api';
import { useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo } from 'react';

export const URL_ACCOUNT_ID_KEY = 'id';
const ACCOUNTS_STALE_TIME = 60 * 1000;
const REFETCH_INTERVAL = 5_000;

type TAccountQueryProps = {
  enabled?: boolean;
  isPollingEnabled?: boolean;
};

export const useAccountsQuery = ({
  enabled = true,
  isPollingEnabled,
}: TAccountQueryProps = {}) => {
  const api = useApi();
  const client = useQueryClient();
  const query = useSuspenseQuery({
    queryKey: ['accounts'],
    queryFn: async () => (enabled ? await api.getAccounts() : null),
    staleTime: ACCOUNTS_STALE_TIME,
    structuralSharing: false,
    refetchInterval: isPollingEnabled && REFETCH_INTERVAL,
  });

  useEffect(() => {
    if (query.data && isPollingEnabled) {
      client.invalidateQueries({ queryKey: ['transfers', 'list'] });
    }
  }, [query.data]);
  return query;
};

export const useAccountQuery = (accountId: string) => {
  const api = useApi();
  return useQuery({
    queryKey: ['accounts', accountId],
    queryFn: () => api.getAccount(accountId),
  });
};

export const useSetAccountIdToUrl = () => {
  const [params, setParams] = useSearchParams();

  return useCallback(
    (accountId?: string) => {
      accountId
        ? params.set(URL_ACCOUNT_ID_KEY, accountId)
        : params.delete(URL_ACCOUNT_ID_KEY);
      setParams(params);
    },
    [params, setParams],
  );
};

export const useAccountIdFromUrl = () => {
  const [params] = useSearchParams();
  return params.get(URL_ACCOUNT_ID_KEY);
};

export const useAccountByUrl = () => {
  const [params] = useSearchParams();
  const id = params.get(URL_ACCOUNT_ID_KEY);
  const { data: accounts } = useAccountsQuery();

  return useMemo(() => {
    return accounts?.find((account) => account.id === id);
  }, [id, accounts]);
};

export const useCNYAccount = () => {
  const { data: accounts } = useAccountsQuery();

  const cnyAccounts = accounts?.filter(account=>account.currency === 'cny') ?? [];
  return cnyAccounts[0]
};
