import { useApi } from '../../state/api';
import {
  TConvertToCNYRateRequestParams,
  TCreateConvertToCNYTransferCommand,
  TCreateFxTransferCommand,
  TCreateOutgoingTransferDto,
  TRateRequestParams,
  TTransferFeeParams,
  TTransfersQueryParams,
  TTransferTypeParams,
} from '@payler/api/client-office';
import { useCallback } from 'react';
import {
  useMutation,
  useQuery,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { PaginationProps } from '@payler/ui-components';
import { DEFAULT_PAGER_PROPS } from '../../const';
import { usePagerPropsFromUrl } from '../../helpers/use-pager-props-from-url';
import { usePagerUrlCallbacks } from '../pager/use-pager-url-callbacks';
import { useTransfersQueryParamsFromUrl } from './url';
import { useSearchParams } from 'react-router-dom';
import { deleteTransferIdsFromUrl } from './helpers';
import {
  createFormData,
  DEFAULT_SORT,
  useGetAxiosError,
} from '@payler/bank-utils';

const STALE_TIME = 60_000;
export const TRANSFER_OUTGOING_ID = 'transferOutgoingId';
export const TRANSFER_INCOMING_ID = 'transferIncomingId';
export const TRANSFER_FX_ID = 'transferFxId';

export const useOutgoingTransferQuery = (transferId: string) => {
  const api = useApi();
  return useSuspenseQuery({
    queryKey: ['transfer', 'outgoing', transferId],
    queryFn: async () =>
      !!transferId ? await api.getOutgoingTransferById(transferId) : null,
    staleTime: STALE_TIME,
  });
};

export const useIncomingTransferQuery = (transferId: string) => {
  const api = useApi();
  return useSuspenseQuery({
    queryKey: ['transfer', 'incoming', transferId],
    queryFn: async () =>
      !!transferId ? await api.getIncomingTransferById(transferId) : null,
    staleTime: STALE_TIME,
  });
};

export const useFxTransferQuery = (transferId: string) => {
  const api = useApi();
  return useSuspenseQuery({
    queryKey: ['transfer', 'fx', transferId],
    queryFn: async () =>
      !!transferId ? await api.getFxTransferById(transferId) : null,
    staleTime: STALE_TIME,
  });
};

/**
 * Подтверждение outgoing трансфера
 */
export const useOutgoingTransferConfirmMutation = () => {
  const client = useQueryClient();
  const api = useApi();
  return useMutation({
    mutationKey: ['transfer', 'outgoing', 'confirm'],
    mutationFn: (data: { transferId: string; confirmationCode: string }) =>
      api.confirmOutgoingTransfer(data.transferId, data.confirmationCode),
    onSettled: () =>
      client.invalidateQueries({ queryKey: ['transfers', 'list'] }),
  });
};

/**
 * Создание outgoing трансфера
 */
export const useCreateOutgoingTransferMutation = () => {
  const api = useApi();
  return useMutation({
    mutationKey: ['transfer', 'outgoing'],
    mutationFn: (transfer: TCreateOutgoingTransferDto) => {
      const formData = new FormData();
      createFormData(formData, transfer);

      return api.createOutgoingTransfer(formData);
    },
  });
};

export const useTransfersQuery = (
  params?: TTransfersQueryParams,
  enabled?: boolean,
) => {
  const api = useApi();
  const filters = useTransfersQueryParamsFromUrl();
  const pagerData = usePagerPropsFromUrl();
  const getError = useGetAxiosError();
  return useQuery({
    queryKey: [
      'transfers',
      'list',
      ...(params ? [params] : []),
      pagerData,
      filters,
    ],
    queryFn: () =>
      api.getTransfers({
        ...DEFAULT_SORT,
        ...pagerData,
        ...filters,
        ...params,
      }),

    staleTime: STALE_TIME,
    throwOnError: (error) => !getError(error).fieldErrors,
    retry: (failureCount, error) =>
      !getError(error).fieldErrors && failureCount <= 3,
    enabled: enabled,
  });
};

export const usePrefetchTransfersQuery = (totalPages?: number) => {
  const api = useApi();
  const filters = useTransfersQueryParamsFromUrl();
  const pagerData = usePagerPropsFromUrl();
  const queryClient = useQueryClient();
  const nextPage = pagerData.PageNumber + 1;
  if (totalPages && nextPage > totalPages) {
    return;
  }

  const prefetchPagerData = {
    ...pagerData,
    PageNumber: nextPage,
  };

  queryClient.prefetchQuery({
    queryKey: ['transfers', 'list', prefetchPagerData, filters],
    queryFn: () =>
      api.getTransfers({
        ...DEFAULT_SORT,
        ...prefetchPagerData,
        ...filters,
      }),
    staleTime: STALE_TIME,
  });
};

export const useTransfersQueryPagerProps = () => {
  const { status, data } = useTransfersQuery();
  const { goToPage, setPageSize } = usePagerUrlCallbacks();
  if (!data || status !== 'success') {
    return { ...DEFAULT_PAGER_PROPS, goToPage, setPageSize } as PaginationProps;
  }
  const { pageNumber, pageSize, totalRecords, totalPages } = data.page;
  return {
    goToPage,
    setPageSize,
    pageSize,
    currentPage: pageNumber,
    totalRecords,
    totalPages,
  } as PaginationProps;
};

export const useIncomingTransferIdFromUrl = () => {
  const [params] = useSearchParams();
  return params.get(TRANSFER_INCOMING_ID);
};

export const useOutgoingTransferIdFromUrl = () => {
  const [params] = useSearchParams();
  return params.get(TRANSFER_OUTGOING_ID);
};

export const useFxTransferIdFromUrl = () => {
  const [params] = useSearchParams();
  return params.get(TRANSFER_FX_ID);
};

export const useIncomingTransferByUrl = () => {
  const id = useIncomingTransferIdFromUrl();
  const { data } = useIncomingTransferQuery(id ?? '');
  return data;
};

export const useOutgoingTransferByUrl = () => {
  const id = useOutgoingTransferIdFromUrl();
  const { data } = useOutgoingTransferQuery(id ?? '');
  return data;
};

export const useFxTransferByUrl = () => {
  const id = useFxTransferIdFromUrl();
  const { data } = useFxTransferQuery(id ?? '');
  return data;
};

export const useSetIncomingTransferIdToUrl = () => {
  const [params, setParams] = useSearchParams();

  return useCallback(
    (transferIncomingId?: string) => {
      deleteTransferIdsFromUrl(params);
      transferIncomingId &&
        params.append(TRANSFER_INCOMING_ID, transferIncomingId);
      setParams(params);
    },
    [params, setParams],
  );
};

export const useSetOutgoingTransferIdToUrl = () => {
  const [params, setParams] = useSearchParams();

  return useCallback(
    (transferOutgoingId?: string) => {
      deleteTransferIdsFromUrl(params);
      transferOutgoingId &&
        params.append(TRANSFER_OUTGOING_ID, transferOutgoingId);
      setParams(params);
    },
    [params, setParams],
  );
};

export const useSetFxTransferIdToUrl = () => {
  const [params, setParams] = useSearchParams();

  return useCallback(
    (transferFxId?: string) => {
      deleteTransferIdsFromUrl(params);
      transferFxId && params.append(TRANSFER_FX_ID, transferFxId);
      setParams(params);
    },
    [params, setParams],
  );
};

export const useCreateExchangeMutation = () => {
  const api = useApi();
  const client = useQueryClient();
  return useMutation({
    mutationKey: ['transfer', 'fx'],
    mutationFn: (data: TCreateFxTransferCommand) =>
      api.createExchangeTransfer(data),
    onSettled: () =>
      client.invalidateQueries({ queryKey: ['transfers', 'list'] }),
  });
};

export const useCreateConvertToCNYMutation = () => {
  const api = useApi();
  const client = useQueryClient();
  return useMutation({
    mutationKey: ['transfer', 'convertToCNY'],
    mutationFn: (data: TCreateConvertToCNYTransferCommand) =>
      api.createСonvertToCNYTransfer(data),
    onSettled: () =>
      client.invalidateQueries({ queryKey: ['transfers', 'list'] }),
  });
};

export const useExchangeTransferConfirmMutation = () => {
  const client = useQueryClient();
  const api = useApi();
  return useMutation({
    mutationKey: ['transfer', 'exchange', 'confirm'],
    mutationFn: (data: { transferId: string; confirmationCode: string }) =>
      api.confirmExchangeTransfer(data.transferId, data.confirmationCode),
    onSettled: () =>
      client.invalidateQueries({ queryKey: ['transfers', 'list'] }),
  });
};

export const useFxRateQuery = (
  { sellAccountId, buyAccountId }: Partial<TRateRequestParams>,
  isRefetchData: boolean,
) => {
  const api = useApi();
  return useQuery({
    queryKey: ['transfer', 'exchange', sellAccountId, buyAccountId],
    queryFn: async () => {
      if (!sellAccountId || !buyAccountId) {
        return;
      }
      return await api.getFxRate({
        sellAccountId: sellAccountId,
        buyAccountId: buyAccountId,
      });
    },
    enabled: !!buyAccountId && !!sellAccountId,
    staleTime: STALE_TIME,
    refetchInterval: (query) => {
      if (query.state.error) {
        // если автоматические ретраи не помогли избавиться от ошибки запроса, то останавливаем refetch
        return false;
      }
      return isRefetchData && 5000;
    },
  });
};

export const useConvertToCNYRateQuery = (
  { buyCurrency, sellCurrency }: Partial<TConvertToCNYRateRequestParams>,
  isRefetchData: boolean,
) => {

  const api = useApi();
  return useQuery({
    queryKey: ['transfer', 'convertToCNY', sellCurrency, buyCurrency],
    queryFn: async () => {
      if (!sellCurrency || !buyCurrency) {
        console.log('exit');
        
        return;
      }
      const result = await api.getConvertToCNYRate({
        sellCurrency,
        buyCurrency,
      });
      console.log('result',result);
      
      return result
    },
    enabled: !!buyCurrency && !!sellCurrency,
    staleTime: STALE_TIME,
    refetchInterval: (query) => {
      if (query.state.error) {
        // если автоматические ретраи не помогли избавиться от ошибки запроса, то останавливаем refetch
        return false;
      }
      return isRefetchData && 5000;
    },
  });
};

export const useTransferTypeQuery = (
  transferTypeParams: TTransferTypeParams,
) => {
  const api = useApi();
  return useSuspenseQuery({
    queryKey: [
      'transfer',
      'type',
      transferTypeParams.Currency,
      transferTypeParams.RecipientId,
    ],
    queryFn: async () =>
      !!transferTypeParams.Currency
        ? await api.getTransferType(transferTypeParams)
        : null,
    staleTime: STALE_TIME,
  });
};

export const useTransferFeeQuery = (
  params: TTransferFeeParams,
  enabled = false,
) => {
  const api = useApi();
  return useQuery({
    queryKey: ['transfer', 'fee', params],
    queryFn: async () => await api.getTransferFee(params),
    staleTime: STALE_TIME,
    enabled,
  });
};

export const useCancelOutgoingTransferMutation = () => {
  const api = useApi();
  const client = useQueryClient();
  return useMutation({
    mutationKey: ['transfer', 'outgoing', 'cancel'],
    mutationFn: (transferId: string) => api.cancelOutgoingTransfer(transferId),
    onSuccess: () =>
      client.invalidateQueries({ queryKey: ['transfers', 'list'] }),
  });
};

export const useCancelExchangeTransferMutation = () => {
  const api = useApi();
  const client = useQueryClient();
  return useMutation({
    mutationKey: ['transfer', 'exchange', 'cancel'],
    mutationFn: (transferId: string) => api.cancelExchangeTransfer(transferId),
    onSuccess: () =>
      client.invalidateQueries({ queryKey: ['transfers', 'list'] }),
  });
};
